import { get, post } from '../../../../api/index';
export const toFaculty = params => get(`/api/infrastruct/v1/facultylist`, params); // 学校院系
export const toTeacher = params => get(`/api/login/v1/foreuser/facultyteacher`, params); // 院系下老师列表接口
export const toPhone = params => post(`/api/login/v1/foreuser/checkpasswordphone`, params); // 忘记密码验证手机号
export const toCode = params => post(`/api/login/v1/foreuser/sendcode`, params); // 忘记密码发送短信验证
export const toPassword = params => post(`/api/login/v1/foreuser/changeforgetpassword`, params); // 忘记密码修改密码
export const feedbackadd = params => post(`/api/feedback/v1/feedbackadd/`, params); //  反馈
export const updateuserhead = params => get(`/api/login/v1/foreuser/updateuserhead`, params); //  更换头像
export const changepassword = params => post(`/api/login/v1/foreuser/changepassword`, params);  //  修改密码
export const changeuserphone = params => post(`/api/login/v1/foreuser/changeuserphone`, params); //修改绑定手机号
export const changeusesendphone = params => post(`/api/login/v1/foreuser/changeusesendphone`, params); //验证码
export const feedbackAdd = params => post(`/api/feedback/v1/feedbackadd`, params); //添加反馈


export const getstudentinfos = params => get(`/api/login/v1/foreuser/getstudentinfos`, params);//  学生信息

export const getuserinfos = params => get(`/api/login/v1/foreuser/getuserinfos`, params);//  获取手机号
export const changenewPassword = params => post(`/api/login/v1/foreuser/changenewpassword`, params); // 登录
export const allschoollist = params => get(`/api/login/v1/foreuser/allschoollist`, params); // 学校列表
export const bingSchool = params => post(`/api/login/v1/foreuser/bingschool`, params); // 绑定学校
export const schoolName = params => get(`/api/login/v1/foreuser/schoolname`, params); // 选择学校
export const changenewpassword = params => post(`/api/login/v1/foreuser/changenewpassword`, params); // 修改密码
export const bingPhone = params => post(`/api/login/v1/foreuser/bingphone`, params); // 绑定手机
// export const getuserinfos = params => get(`/api/login/v1/foreuser/getuserinfos`, params); // 选择学校
export const setDefault = params => post(`/api/login/v1/foreuser/setdefault`, params); // 默认学校
export const checkSiteid = params => post(`/api/login/v1/foreuser/checksiteid`, params); // 切换账号
export const sendsmscode = params => post(`/api/login/v1/foreuser/sendsmscode`, params); // 登录
export const changeaccountname = params => post(`/api/login/v1/foreuser/changeaccountname`, params); // 修改账号
export const unBundling = params => post(`/api/login/v1/binding/unbundling`, params); // 解绑第三方

// 小米考试列表
export const schoolextinfo = params => get(`/api/login/v1/after/schoolext/info`, params); //根据学校id获取学校信息

// 获取订单列表
export const getOrderList = params => post(`/api/transaction/v1/order/list`, params);  // 获取用户订单列表

